<template>
  <div >
    <v-dialog v-model="dialog" max-width="750" persistent overlay-opacity="0.94">
        <v-img alt="company-image"
             :src="item">
          <div class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <vue-feather style="color: white" size="17" type="x"/>
            </v-btn>
          </div>
        </v-img>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CompanyImageView",
  components: {},
  data(){
    return{
      question:"",
      answer:"",
      // dialog:false,
      loading: false
    }
  },
  props:{
    item: String,
    dialog: {
      type: Boolean,
      default: false
    },
  },
  created() {

  },
  methods:{
    closeDialog() {
      this.$emit('closeModal')
    },
  }
}
</script>

<style scoped lang="scss">


</style>