<template>
  <div class="">
    <slot name="header"></slot>
    <div>
      <div class="">
        <slot name="dashboard-content-header"></slot>
      </div>
      <div class="tw--mt-8">
        <slot name="dashboard-company-image"></slot>
      </div>
      <v-row no-gutters class="tw-pr-3 mt-5 tw-pb-1 lg:tw-px-10" >

        <v-col cols="12" md="3" sm="12" class="columnOne">
          <slot name="dashboard-sideNav"></slot>
        </v-col>
        <v-col sm="12" md="9" cols="12">
          <div class="tw-mt-6">
            <slot name="dashboard-content"></slot>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewCompanyLayout"
}
</script>

<style scoped lang="scss">
.columnOne{
  @media (min-width: 960px) {
    border-right: 1px solid rgba(208, 220, 228, .3);
  }
}
</style>