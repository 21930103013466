<template>
  <div>
    <v-dialog v-model="dialog" max-width=" 450" >
      <template v-slot:activator="{ on, attrs }">
        <div
            v-bind="attrs"
            id="create-faq"
            v-on="on"
            @click="getDetails()"
        >
          <slot></slot>
        </div>
      </template>

      <v-card class="pa-5 mainCard" width="450">
        <div class="justify-space-between d-flex align-center">
          <h4>{{ modalTitle }}</h4>
          <div class="d-flex ml-1 align-center justify-center" style="cursor: pointer" @click="closeDialog()">
            <vue-feather type="x" stroke="#475661" size="24" class="mr-1"/>
          </div>

        </div>
        <ValidationObserver ref="observer" v-slot="{handleSubmit}">
          <v-form class="tw-pt-7" id="faq" @submit.prevent="handleSubmit(createFaq)">
            <div class="mb-2">
              <div  class="mb-1">
                <small>Question</small>
              </div>
              <ValidationProvider
                  name="question"
                  rules="required"
                  v-slot="{ errors }">
                <v-text-field id="question" solo v-model="question"/>
                <span v-show="errors" class="err">{{ errors[0] }} </span>
              </ValidationProvider>
            </div>
            <div class="mb-2">
              <div  class="mb-1">
                <small>Answer</small>
              </div>
              <ValidationProvider
                  name="answer"
                  rules="required"
                  v-slot="{ errors }">
                <v-textarea id="answer" solo v-model="answer"/>
                <span v-show="errors" class="err">{{ errors[0] }} </span>
              </ValidationProvider>
            </div>

            <div class="tw-flex actions tw-justify-start sm:tw-justify-end mt-12">
              <base-button type="button" @click="closeDialog()" depressed id="cancel" class="mr-4" outlined  button-text="Cancel"/>
              <base-button type="submit" :loading="loading" depressed id="save" button-text="Save"/>
            </div>

          </v-form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import getSiteName from "@/services/utils";
export default {
name: "CreateFAQModal",
  components: {BaseButton},
  data(){
  return{
    question:"",
    answer:"",
    dialog: false,
    loading: false
  }
  },
  props:{
    modalTitle: String,
    item: Object,
  },
  created() {

  },
  methods:{
    getDetails(){
      if(this.modalTitle === "Edit FAQ Details"){
        console.log(this.item.id)
          this.question = this.item.question,
          this.answer = this.item.answer
      }
    },
    createFaq(){
      this.loading = true
      let data = {
        question: this.question,
        answer: this.answer
      }
      let site_name = getSiteName()

      if(this.modalTitle === "Edit FAQ Details"){
        let id = this.item.id
        console.log(id)
        this.$store.dispatch("editCompany/updateSingleCompanyFaq", {faq_id : id, data: data} ).then(
            res=>{
              console.log(res)
              this.$store.dispatch("editCompany/getCompanyFaqs", this.$siteName())
              this.closeDialog()
            }
        ).catch(
            err =>{
              console.log(err)
            }
        ).finally(()=>{
          this.loading = false
        })
      }
      else if(this.modalTitle === "Create FAQ"){
        this.$store.dispatch("editCompany/createFaq", {site_name:site_name,data: data}).then(
            res => {
              console.log(res)
              this.closeDialog()
            }
        ).catch(
            err => {
              console.log(err)
            }
        ).finally(() => {
          this.loading = false
        })
      }

    },
    closeDialog() {
      this.dialog = false;
      this.clearForm()
      this.$refs.observer.reset()
    },
    clearForm() {
      this.question = ""
      this.answer = ""
    },
  }
}
</script>

<style scoped lang="scss">
h4{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
}
.actions {

@media (max-width: 350px) {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
}
.actions #cancel{
@media (max-width: 350px) {
  margin-top: -20px;
  margin-left: 16px;
}
}
.actions #save{
@media (max-width: 350px) {
  margin-top: 10px;
}
}
.err{
  font-family: "DM Sans",serif;
  color: red;
  font-size: 13px;
}
#faq ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details{
  display: none;
}
</style>