<template>
  <div v-if="getReviews.length === 0" style="max-width: 630px ">
    <CompanyProfileEmptyState>
<!--      <img alt="No Review"-->
<!--           src="https://res.cloudinary.com/semicolon-dev/image/upload/v1635352532/semicolonAfrica/Frame_48096393_fibuab.png"/>-->
      <ReviewIcon/>
      <h2 class="tw-pt-8 no-reviews-text">No reviews have been written </h2>
    </CompanyProfileEmptyState>
  </div>
  <div v-else :class="[$vuetify.breakpoint.mobile?'tw-pl-3':'scroll tw-px-6', 'tw-mt-10']">
    <div class="d-flex tw-justify-between flex-wrap wrapper lg:px-5 mx-auto">
      <div :class="[!$vuetify.breakpoint.smAndUp?'flex-grow-1':'']">
        <div class="tw-flex" :class="[$vuetify.breakpoint.mobile?'tw-mt-3':'']">
          <div class="tw-flex align-center">
            <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <td class="d-flex">
                  <div class="d-flex align-center" v-bind="attrs" v-on="on" icon>
                    <vue-feather style="width: 15px; color: #475661;" class="mr-2" type="filter"/>
                    <small class="mr-1 mt-1">Filter by</small>
                    <vue-feather style="color: #475661; width: 15px" type="chevron-down"/>
                  </div>
                </td>
              </template>
              <v-list class="tw-cursor-pointer checkFont">
                <v-list-item v-for="(sort,i) in sortBy" :key="i">
                  <v-checkbox @click="sort.isClicked=!sort.isClicked, getSortedRatings(sort)" :label="sort.label"/>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="ratingClass lg:tw-hidden md:tw-hidden lg:px-4 lg:tw-mt-6 mb-5 ">
          <v-card style="box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08); border-radius: 12px"
                  class="tw-px-3 tw-py-3"
          >
            <v-card-title class="justify-center">

              <span class="guidesTitle">              TOTAL RATINGS</span>
            </v-card-title>
            <v-card-text>
              <v-card
                  style="box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08); box-sizing: content-box; border-radius: 12px; background-color: #F6FCFF">
                <v-card-text class="justify-center tw-flex-wrap tw-flex align-center">
                  <v-rating
                      v-model="getReviewInsight.overall_rating"
                      empty-icon="mdi-star-outline"
                      full-icon="mdi-star"
                      readonly
                      background-color=""
                      color="#FECB54"
                      half-increments
                      size="20"

                  ></v-rating>
                  <span class="tw-ml-2">{{ getReviewInsight.overall_rating ? getReviewInsight.overall_rating.toFixed(1) : 0 }} out of 5</span>

                </v-card-text>
              </v-card>
              <v-card-title class=" tw-flex align-center tw-justify-between">
                <span class="guidesTitle">{{ getReviews.count}}{{getReviews.count >1?' Ratings':' Rating' }}</span>
                <div class="show" @click="showRatings=!showRatings">
                  {{ showRatings ? 'Show less...' : 'Show more...' }}
                </div>
              </v-card-title>
              <v-card-text v-if="showRatings">
                <div class="tw-flex align-center pb-4" v-for="(rate, key, i) in getEachStar" :key="i">
                  <div :class="['tw-flex', 'align-center',key === '1'?'tw-pr-3': 'tw-pr-2']">
                    <small class="pr-2 progressSmall">{{
                        key
                      }}</small><small class="progressSmall">{{ key === '1' ? ' Star' : ' Stars' }}</small>
                  </div>
                  <v-progress-linear
                      v-model="rate.perc"
                      color="#FECB54"
                      height="8"
                      style="border-radius: 24px;max-width: 180px"
                  ></v-progress-linear>
                  <small class="tw-pl-2 progressSmall">{{ rate.perc }}%</small>
                </div>
              </v-card-text>
            </v-card-text>
          </v-card>
        </div>

        <div v-if="filteredReturn <= 0">
          <span class="font-weight-bold tw-mt-4"> No Reviews</span>
        </div>

        <v-card style="background: #FFFFFF;box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);border-radius: 12px;"
                v-else
                class="mb-5 ratingClass " max-width="573" :min-width="$vuetify.breakpoint.mobile?'':'570'"
                v-for="info in getReviews.results" :key="info.id">
          <v-card-text class=" px-4 py-4">
            <div class="tw-flex align-center tw-justify-between">
              <h3>{{ info.title }}</h3>
              <small>{{ dateCreated(info) }}</small>
            </div>
            <v-rating
                v-model="info.rating"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                background-color=""
                readonly
                color="#FECB54"
                size="20"
                class="pb-3 ml-n3 mt-n2"
            ></v-rating>
            <div style="line-break: anywhere">
              <h3>Pros</h3>
              <p>{{ info.pros }}</p>
            </div>
            <div style="line-break: anywhere">
              <h3>Cons</h3>
              <p>{{ info.cons }}</p>
            </div>
            <div class="tw-flex font-weight-bold align-center tw-justify-between">
              <div class="tw-flex align-center">
                <v-avatar v-if="info.name" class="mr-2" style="background-color:#EEF8FF; color: #008EEF;" size="35">
                    <span class="red-text initial"
                    >{{
                        info.name.split(" ")[0].charAt(0).toUpperCase()
                      }}{{ info.name.split(" ")[1].charAt(0).toUpperCase() }}</span
                    >
                </v-avatar>
                <h2 v-if="info.name" style="color:#008EEF ">{{ info.name }}</h2>
              </div>
              <div>
                <small>{{ info.is_enum_hired ? 'Verified Employee' : '' }} </small>
              </div>
            </div>
          </v-card-text>

        </v-card>
                <div v-if="!filteredReturn.length <= 0 && getReviews.count > 5 " >
                  <v-pagination
                      v-model="page"
                      color="#008EEF"
                      @input="getPage()"
                      @previous="getPage()"
                      @next="getPage()"
                      :length="Math.round(getReviews.count/5)"
                      :total-visible="2"
                  ></v-pagination>
                </div>
      </div>


      <div v-if="$vuetify.breakpoint.smAndUp && getReviews.length !== 0" class="tw-position-fixed pb-16 mb-5 lg:tw-pt-5">

        <v-card style="box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08); border-radius: 12px"
                class="organization__info ratingClass tw-px-3 tw-py-3" width="100%"
                min-width="320">
          <v-card-title class="justify-center">

            <span class="guidesTitle">              TOTAL RATINGS</span>
          </v-card-title>
          <v-card-text>
            <v-card style="box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08); border-radius: 12px; background-color: #F6FCFF">
              <v-card-text class="justify-center tw-flex align-center">
                <v-rating
                    v-model="getReviewInsight.overall_rating"
                    empty-icon="mdi-star-outline"
                    full-icon="mdi-star"
                    readonly
                    background-color=""
                    color="#FECB54"
                    half-increments
                    size="30"

                ></v-rating>
                <span class="ml-2">{{ getReviewInsight.overall_rating.toFixed(1) }} out of 5</span>

              </v-card-text>
            </v-card>
            <v-card-title class="justify-center">
              <span class="guidesTitle">{{ getReviews.count}}{{getReviews.count >1?' Ratings':' Rating' }}</span>
            </v-card-title>
            <v-card-text>
              <div class="tw-flex align-center pb-4" v-for="(rate, key, i) in getEachStar" :key="i">
                <div :class="['tw-flex', 'align-center',key === '1'?'tw-pr-3': 'tw-pr-2']">
                  <small class="pr-2 progressSmall">{{
                      key
                    }}</small><small class="progressSmall">{{ key === '1' ? ' Star' : ' Stars' }}</small>
                </div>
                <v-progress-linear
                    v-model="rate.perc"
                    color="#FECB54"
                    height="8"
                    style="border-radius: 24px;width: 180px"
                ></v-progress-linear>
                <small class="tw-pl-2 progressSmall">{{ rate.perc }}%</small>
              </div>
            </v-card-text>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Month from "@/components/dashboard/reuseables/month";
import CompanyProfileEmptyState from "@/components/dashboard/reuseables/CompanyProfileEmptyState";
import ReviewIcon from "@/components/dashboard/reuseables/ReviewIcon";

export default {
  name: "Reviews",
  components: {ReviewIcon, CompanyProfileEmptyState},
  data() {
    return {
      showRatings: false,
      page: 4,
      showWrite: false,
      filteredReturn : [{}],
      sortBy:[
        {
          id:0,
          label:'Verified Employee',
          isClicked:false
        },
        {
          id:1,
          label:'Stars - High to Low',
          isClicked:false
        },
        {
          id:2,
          label:'Stars - Low to High',
          isClicked:false
        }
      ]
    }
  },
  computed: {
    ...mapGetters('editCompany',['getReviews','getReviewInsight']),
    user() {
      let fullname = JSON.parse(sessionStorage.getItem('userData'))
      return fullname.name
    },
    getEachStar() {
      const rates = this.getReviewInsight.rating_distribution
      // this.getReviews.forEach(review => ++rates[review.rating])
      // const sum = Object.values(rates).reduce((acc, curr) => acc + curr, 0)
      for (const [key, value] of Object.entries(rates)) {
        rates[key] = {
          count: value,
          // perc: value ? ((value / sum) * 100).toFixed(0) : 0
          perc: value ? value.toFixed(0) : 0

        }
      }
      return rates
    },
    dateCreated() {
      return function (review) {
        if (review.created_at !== '') {
          return Month[review.created_at.split("-")[1]] +
              " " +
              review.created_at.split("-")[2] + ', ' + review.created_at.split("-")[0];
        } else return ''
      };
    },
    totalRatings() {
      let rating = 0
      this.getReviews.filter(r => {
        let m = rating + r.rating
        rating = m
      })
      rating = rating / this.getReviews.length
      let result = Math.round(rating * 2) / 2
      return result
    }
  },
  methods: {
    getSortedRatings(data){
      let reviews = this.getReviews.results
      if(data.label === 'Verified Employee' && data.isClicked) {
        reviews = reviews.filter(item => item.is_enum_hired === true)
      } else if(data.label === 'Stars - High to Low' && data.isClicked) {
        reviews = reviews.sort((a, b) => parseFloat(b.rating) - parseFloat(a.rating));
      } else if (data.label === 'Stars - Low to High' && data.isClicked){
        reviews = reviews.sort((a, b) => parseFloat(a.rating) - parseFloat(b.rating));
      } else return reviews
      if (!reviews.length) {
        // this.$displaySnackbar({message:'No filtered result',success:false})
        this.filteredReturn = []
      } else this.filteredReturn = [{}]
      return reviews
    },
    scrollNow() {
      document.getElementById('displayReviews').scrollIntoView();
    },
    getPage() {
      let data = {}
      data.id = sessionStorage.getItem('companyId')
      data.page_num = this.page
      this.scrollNow()
      this.$store.dispatch('fetchPaginatedReview', data)

    },
  }
}
</script>

<style scoped lang="scss">
h3 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #001343;
}

.cardSize {
  max-width: 573px;
  min-width: 573px;
  @media (max-width: 768px) {
    min-width: unset;
  }

}

.progressSmall {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #333333;
}

small {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #9CABB5;

}

.show {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #9CABB5;
}

.guidesTitle {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #333333;
}

.scroll {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 15px !important;
  border-radius: 6px;
}
</style>