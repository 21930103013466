<template>

  <div class="mainDiv tw-mb-4">
    <div class="d-flex justify-end tw-my-4">
      <CreateFAQModal modal-title="Create FAQ">
        <v-btn class="text-capitalize transparent-btn">
          <vue-feather type="plus" stroke="#008EEF" size="16" class="mr-1"/>
          <span>Add FAQ</span>
        </v-btn>
      </CreateFAQModal>
    </div>

    <div v-if="companyFaqs.length === 0">
      <CompanyProfileEmptyState>
<!--        <img alt="No FAQs" src="https://res.cloudinary.com/semicolon-dev/image/upload/v1635339414/semicolonAfrica/Frame_48096393_s9eufl.png"/>-->
        <FaqIcon/>
        <h2 class="tw-pt-8 no-faq-text">No FAQs</h2>
      </CompanyProfileEmptyState>
    </div>
  <div v-else class="faqCard">
    <v-expansion-panels flat accordion>
      <v-expansion-panel class="panel"
          v-for="(item, index) in companyFaqs" :key="index"
      >
        <v-expansion-panel-header>
          <div class="d-flex align-center justify-space-between">
            <div class="question text-capitalize">
              {{ item.question }}
            </div>

            <v-menu bottom offset-y left >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <vue-feather type="more-vertical"/>
                </v-btn>
              </template>
              <v-list style="width: 192px">
                <v-list-item class="mx-1 mt-1">
                  <v-list-item-title id="edit">
                    <CreateFAQModal :item="item" modal-title="Edit FAQ Details">
                      <h6>Edit</h6>
                    </CreateFAQModal>
                  </v-list-item-title>
                </v-list-item>
                <div  class="menu">
                  <v-divider></v-divider>
                </div>
                <v-list-item class="mx-1 mt-1">
                  <v-list-item-title  id="delete">
                    <DeleteModal :name="'question'"  :loading="loading" @delete="deleteItem(item)">
                      <h6>Delete</h6>
                    </DeleteModal>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          </v-expansion-panel-header>
        <v-expansion-panel-content class="answer">
          {{ item.answer }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import CreateFAQModal from "@/components/dashboard/components/companyDetails/modals/CreateFAQModal";
import CompanyProfileEmptyState from "@/components/dashboard/components/companyDetails/CompanyProfileEmptyState";
import DeleteModal from "@/components/dashboard/components/companyDetails/modals/DeleteModal";
import FaqIcon from "@/components/dashboard/reuseables/FaqIcon";
export default {
name: "FAQs",
  components: {FaqIcon, DeleteModal, CompanyProfileEmptyState, CreateFAQModal},
  computed:{
    ...mapGetters('editCompany', ["companyFaqs"])
  },
  data(){
  return{
    loading: false,
    dialog: false
  }
  },
  methods:{
    deleteItem(item){
      this.loading = true
      this.$store.dispatch("editCompany/deleteCompanyFaq", item.id).then(
          res =>{console.log(res)
          this.dialog = false}
      ).catch(err =>{console.log(err)}).finally(()=>{this.loading = false})
    },
    closeDialog() {
      this.dialog = false;
    },
  }
}
</script>

<style scoped lang="scss">
.transparent-btn {
  background-color: transparent !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
span{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}
.mainDiv{
  max-width: 630px;
}
.question{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}
.answer{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.panel{
  border-bottom: 1px solid rgba(208, 220, 228, .3);
}
.faqCard {
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
@media (max-width: 640px) {
  max-height: 30vh;
}
}

.faqCard::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.faqCard::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 20px !important;
  border-radius: 6px;
}
</style>