<template>
  <div>
    <div class="d-flex uploadDiv  justify-end tw-my-4">
      <v-card v-model="showDragDiv" class="text-capitalize pb-2" flat>
        <div
            v-if="companyDescriptionAndMedia.media_gallery.length <8"
        >
          <input
              id="assetsFieldHandle"
              ref="file"
              accept="image/png, image/gif, image/jpeg,image/jpg"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              name="fields[assetsFieldHandle][]"
              style="display: none"
              type="file"
              @change="uploadToCloudinary()"

          />
          <label class="d-flex align-center" for="assetsFieldHandle" style="cursor: pointer">
            <vue-feather class="mr-1" size="17" stroke="#008EEF" type="upload"/>
            <span>Upload Image</span>
          </label>

        </div>
      </v-card>
    </div>
    <div v-if="companyDescriptionAndMedia.media_gallery.length === 0" style="max-width: 630px ">
      <CompanyProfileEmptyState>
        <!--        <img alt="No Image"-->
        <!--             src="https://res.cloudinary.com/semicolon-dev/image/upload/v1635352532/semicolonAfrica/Frame_48096393_fibuab.png"/>-->
        <MediaIcon/>
        <h2 class="tw-pt-8 no-reviews-text">No image has been uploaded </h2>
      </CompanyProfileEmptyState>
    </div>
    <v-row v-else>

      <div v-if="loading">
        <v-card class="text-center  mt-3" flat
                style="
                  background: #D0DCE4;
                  border-radius: 6px;
                  width: 212px;
                  height: 210px;

            "
        >
          <v-progress-circular
              class="tw-mt-16"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-card>

      </div>
      <v-col
          v-for="(image, index) in companyDescriptionAndMedia.media_gallery"
          :key="index"
          cols="12"
          md="3"
          sm="6"
          xs="12"
      >
        <v-img :src="image.image" class="img tw-cursor-pointer" @click="[openModal(image.image)]">
          <div class="d-flex justify-end">
            <div class="tw-p-1.5 tw-mt-2 tw-mr-2 delete" @click.stop="openDelete(image.url,index)">
              <vue-feather size="17" type="trash-2"/>
            </div>
          </div>
        </v-img>
      </v-col>
    </v-row>
    <CompanyImageView :dialog="dialog" :item="imageInView" @closeModal="closeDialog"/>
    <DeleteImage
        :dialog="show"
        :loading="loading"
        name="Image"
        @click="deleteImage(imageData.pic,imageData.id)"
        @close="closeImageModal"
    />
  </div>

</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import CompanyProfileEmptyState from "@/components/dashboard/reuseables/CompanyProfileEmptyState";
import {removeImage, uploadCompanyMedia} from "@/services/api/APIService";
import CompanyImageView from "@/components/dashboard/components/companyDetails/modals/CompanyImageVIew";
import DeleteImage from "@/components/dashboard/components/companyDetails/modals/DeleteImage";
import MediaIcon from "@/components/dashboard/reuseables/MediaIcon";
import {uploadMediaFile} from "@/services/utils/fileUploader";
import {viewFile} from "@/services/storage/aws";

export default {
  name: "Media",
  components: {MediaIcon, DeleteImage, CompanyImageView, CompanyProfileEmptyState},
  data() {
    return {
      showDragDiv: true,
      loading: false,
      show: false,
      image_url: '',
      imageData: {
        pic: '',
        id: 1
      },
      dialog: false,
      imageInfo: {},
      file: "",
      imageInView: ''
    }
  },
  computed: {
    ...mapState('editCompany', ['companyProfile']),
    ...mapGetters('editCompany', ['companyDescriptionAndMedia', 'getCompanyProfile']),
  },
  methods: {
    ...mapMutations('editCompany', ['setMedia']),
    openDelete(pic, id) {
      console.log({pic})
      this.imageData.id = id
      this.imageData.pic = pic
      this.show = true
    },
    closeImageModal() {
      this.show = false
    },
    closeDialog() {
      this.dialog = false
    },
    async handleImage(file) {
      const {key: url, size, name} = await uploadMediaFile(file, 'company/media')
      this.imageInfo = {url: url, name: name, size: size};
      let site_name = (sessionStorage.getItem('site_name'))
      let data = {}
      data.image = this.imageInfo.url
      await uploadCompanyMedia(site_name, data).then(res => {
            console.log(res)
            this.$displaySnackbar({message: 'Image Uploaded', success: true})
        const media = {
          url: this.imageInfo.url,
        }
        viewFile({ url: media.url, context: media })
            this.setMedia(media)
          }
      ).catch(e => {
            console.log(e)
            this.$displaySnackbar({message: 'Image failed to Upload', success: false})
          }
      )
    },
    openModal(image) {
      this.imageInView = image
      this.dialog = true
    },
    openDeleteModal() {
      this.show = true
    },
    async uploadToCloudinary() {
      console.log(this, this.companyProfile)
      if (!this.companyProfile.verified) {
        this.$displaySnackbar({message: 'Your organisation has not been verified', success: false})
        return
      }
      this.loading = true
      this.file = this.$refs.file.files[0];
      const exts = ['jpeg', 'png', 'gif', 'jpg']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension.toLowerCase())) {
        this.$displaySnackbar({message: 'Only accepts Image files', success: false})
        this.file = "";
        return
      }
      try {
        await this.handleImage(this.file);
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async deleteImage(image, id) {
      console.log({image, id})
      this.loading = true
      this.companyDescriptionAndMedia.media_gallery.splice(id, 1)
      let site_name = (sessionStorage.getItem('site_name'))
      let data = {}
      data.image = image
      await removeImage(site_name, data).then(res => {
            console.log(res)
            this.$displaySnackbar({message: 'Image Deleted', success: true})
            this.loading = false
            this.show = false
          }
      ).catch(e => {
            console.log(e)
            this.$displaySnackbar({message: 'Image failed to Delete', success: false})
            this.loading = false
            this.show = false

          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.img {
  /*width: 315px;*/
  border-radius: 6px;
  height: 210px;
  /*object-fit: cover;*/
}

.delete {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  cursor: pointer;
}

.delete:hover {
  background-color: #303030;
}

.delete:hover ::v-deep svg {
  color: #FFFFFF;
}

span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}

.uploadDiv {
  border-bottom: 1px solid rgba(208, 220, 228, .3);

}
</style>