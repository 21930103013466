<template>
<div>
  <v-dialog v-model="dialog" max-width=" 450" >
    <template v-slot:activator="{ on, attrs }">
      <div
          v-bind="attrs"
          id="create-award"
          v-on="on"
          @click="getDetails"
      >
        <slot></slot>
      </div>
    </template>

    <v-card class="pa-5 mainCard" width="450">
      <div class="justify-space-between d-flex align-center">
        <h4>{{ modalTitle }}</h4>
        <div class="d-flex ml-1 align-center justify-center" style="cursor: pointer" @click="closeDialog()">
          <vue-feather type="x" stroke="#475661" size="24" class="mr-1"/>
        </div>

      </div>

      <ValidationObserver ref="observer" v-slot="{handleSubmit}">
        <v-form class="tw-pt-7" id="award" @submit.prevent="handleSubmit(createAward)">
          <div class="mb-2">
            <div  class="mb-1">
              <small>Title</small>
            </div>
            <ValidationProvider
                name="title"
                rules="required"
                v-slot="{ errors }">
              <v-text-field id="title" solo v-model="title"/>
              <span v-show="errors" class="err">{{ errors[0] }} </span>
            </ValidationProvider>
          </div>
          <div class="mb-2">
            <div  class="mb-1">
              <small>Organiser</small>
            </div>
            <ValidationProvider
                name="Organiser"
                rules="required"
                v-slot="{ errors }">
              <v-text-field id="Organiser" solo v-model="organiser"/>
              <span v-show="errors" class="err">{{ errors[0] }} </span>
            </ValidationProvider>
          </div>

          <div class="mb-2">
            <div  class="mb-1">
              <small>Year Awarded</small>
            </div>
            <ValidationProvider
                name="Year Awarded"
                rules="required|numeric|max:4"
                v-slot="{ errors }">
              <v-text-field id="year" solo v-model="year_awarded"/>
              <span v-show="errors" class="err">{{ errors[0] }} </span>
            </ValidationProvider>
          </div>


          <div class="mb-4">
            <div  class="mb-1 tw-flex">
              <small >Upload Logo</small>
            </div>
            <v-card :loading="loading" v-if="!fileName" flat v-model="showDragDiv">
              <div
                  v-show="file === ''"
                  :style="{ 'background-color': color }"
                  class="drag-div tw-bg-enumBlue5"
                  @dragleave="dragleave"
                  @dragover="dragover"
                  @drop="drop"
                  id="drop"
              >
                <input
                    id="assetsFieldHandle"
                    ref="file"
                    accept="image/*"
                    class="w-px h-px opacity-0 overflow-hidden absolute"
                    name="fields[assetsFieldHandle][]"
                    style="display: none"
                    type="file"
                    @click="onInputClick"
                    @change="getFile()"

                />
                <label class="d-flex px-1 pt-3 align-center justify-center upload-label flex-column cursor-pointer" for="assetsFieldHandle">
                  <UploadIcon class="tw-text-enumGrey2"/>
                  <span class="drag-drop mx-auto">
                    <p class="font-weight-bold tw-text-enumGrey2 my-2">Drag and drop or <span class="choose__file">choose image</span></p>
                    <p class="size__instruction tw-text-enumGrey3 d-block ">240x240 px Recommended, max size 1MB  </p>
                  </span>
                </label>
              </div>
            </v-card>
            <div v-if="file && loading" class="file" style="cursor: pointer">
              <div class="d-flex align-center justify-center pt-3 px-3">
                <span >Loading...</span>
              </div>
            </div>
            <div v-if="(file || fileName) && !loading" class="file" style="cursor: pointer">
              <div class="d-flex justify-space-between pt-3 px-3">
                <v-icon> mdi-file-outline</v-icon>
                <span >{{shortenedTitle( file.name) || fileName }}</span>
                <v-icon @click="remove" id="remove">mdi-close-thick</v-icon>
              </div>
            </div>
          </div>


          <div class="tw-flex actions tw-justify-start sm:tw-justify-end mt-12">
            <base-button type="button" @click="closeDialog()" :depressed="true" id="cancel" class="mr-4" :outlined="true" button-text="Cancel"/>
            <base-button type="submit" :loading="createLoading" :depressed="true" id="save" button-text="Save"/>
          </div>

        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {UploadIcon} from "vue-feather-icons"
import getSiteName from "@/services/utils";
import {uploadMediaFile} from "@/services/utils/fileUploader";
export default {
name: "CreateAwardModal",
  components: {BaseButton, UploadIcon},
  data(){
  return{
    title: "",
    year_awarded:"",
    organiser:"",
    dialog: false,
    file: "",
    fileName:"",
    editFileName:"",
    loading: false,
    createLoading: false,
    showDragDiv: true,
    color: "",
    image:"",
    image_name: ""

  }
  },
  props:{
    modalTitle: String,
    item: Object,
  },
  methods: {
    getDetails(){
      if(this.modalTitle === "Edit Award Details"){
        this.title = this.item.title,
        this.year_awarded = this.item.year_awarded,
        this.organiser = this.item.organiser,
        this.image = this.item.image
        this.fileName = this.item.image_name
        this.editFileName = this.item.image_name
      }
    },
    async handleImage(file) {
      const { key: company_award_url, name: company_award } = await uploadMediaFile(file, 'company/awards')
      this.image = company_award_url
      this.imageName = company_award
      console.log(this.imageName, this.image)
    },
    async createAward() {
      this.createLoading = true
      let site_name = getSiteName()
      if(this.modalTitle === "Award Details") {
        console.log(this.file)
          if(this.file !== "") {
            try {
              await this.handleImage(this.file);
              // this.isUploading = false;
            } catch (e) {
              this.createLoading = false;
              this.$displaySnackbar({
                message: "Image failed to upload, Please check your internet",
                success: false
              });
              return;
            }
          }
        let data = {
          title: this.title,
          organiser: this.organiser,
          year_awarded: this.year_awarded,
          image: this.image,
          image_name: this.imageName

        }
        console.log(data)
        this.$store.dispatch("editCompany/createAward", {site_name: site_name, data: data}).then(
            res => {
              console.log(res)
              this.closeDialog()
            }
        ).catch(
            err => {
              console.log(err)
            }
        ).finally(() => {
          this.createLoading = false
        })
      }
      else if(this.modalTitle === "Edit Award Details"){

        console.log(this.file.name)
        console.log(this.item.image_name)
         if(this.editFileName !== (this.file.name || this.fileName)){
          try {
            await this.handleImage(this.file);
            // this.isUploading = false;
          } catch (e) {
            this.createLoading = false;
            this.$displaySnackbar({
              message: "Image failed to upload, Please check your internet",
              success: false
            });
            return;
          }
        }
        let data = {
          title: this.title,
          organiser: this.organiser,
          year_awarded: this.year_awarded,
          image: this.image,
          image_name: this.file.name
        }

        let id = this.item.id
      //   console.log(id)
        console.log(data)
        this.$store.dispatch("editCompany/updateSingleCompanyAward", {award_id: id, data: data}).then(
            res => {
              console.log(res)
              this.$store.dispatch("editCompany/getCompanyAwards", this.$siteName())
              this.closeDialog()
            }
        ).catch(
            err => {
              console.log(err)
            }
        ).finally(() => {
          this.createLoading = false
        })
      }

    },
    getFile(){
      this.file = this.$refs.file.files[0];
      console.log(this.file)
      const exts = ['png', 'tiff', 'jpg', 'jpeg']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      console.log(fileExtension)
      if (!exts.includes(fileExtension)) {
        this.$displaySnackbar({message: 'Please upload file in recommended format', success: false})
        this.file = "";
        return
      }
      this.showDragDiv = false;
    },
    closeDialog() {
      this.dialog = false;
      this.clearForm()
      this.$refs.observer.reset()
    },
    clearForm() {
      this.title = ""
      this.year_awarded = ""
      this.organiser = ""
      this.file = ""
      this.image = ""
    },
    onInputClick (event) {
      event.target.value = ''
    },
    shortenedTitle(file_name) {
      if (!file_name) return
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, 20)
    },
    onChange() {
      this.file = this.$refs.file.files[0];
      this.showDragDiv = false;
    },
    dragover(event) {
      event.preventDefault();
      this.color = "#F6F6F6";
    },
    dragleave() {
      // Clean up
      this.color = "#FBFDFE";
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      this.color = "#FBFDFE";
    },
    remove() {
      this.file = "";
      this.fileName = "";
      this.showDragDiv = true;
    }
  }
}
</script>

<style scoped lang="scss">
h4{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
}
.actions {

  @media (max-width: 350px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
.actions #cancel{
  @media (max-width: 350px) {
    margin-top: -20px;
    margin-left: 16px;
  }
}
.actions #save{
  @media (max-width: 350px) {
    margin-top: 10px;
  }
}
.drag-div {
  border: 1px dashed #008EEF;
  box-sizing: border-box;
  border-radius: 10px;
  height: 118px;
}

.drag-drop {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.file {
  border-radius: 8px;
  height: 50px;
  border: 1px solid #eaeaea;
  margin-bottom: 28px;
}

.upload-label {
  height: 100%;
  cursor: pointer;
}

.choose__file {
  color: #008EEF;
  font-family: "DM Sans",serif;
}

.size__instruction {
  font-size: 11px;
}
.err{
  font-family: "DM Sans",serif;
  color: red;
  font-size: 13px;
}
.mainCard::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.mainCard::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 20px !important;
  border-radius: 6px;
}
#award ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details{
  display: none;
}
</style>