import { render, staticRenderFns } from "./ReviewIcon.vue?vue&type=template&id=572c0d19&scoped=true&"
import script from "./ReviewIcon.vue?vue&type=script&lang=js&"
export * from "./ReviewIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572c0d19",
  null
  
)

export default component.exports