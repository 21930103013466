<template>
<section class="tw-pl-0 md:tw-pl-8">
  <div v-if="isLoading">
    <Loader loading/>
  </div>
  <div v-else>
    <div class="tabbs tw-pl-2 md:tw-pl-0">
      <v-tabs show-arrows v-model="active_tab">
        <v-tab  v-for="(tab, i) in tabs" :key="i" @click="switchTab(tab.name)">
          <div class="text-capitalize"  >
            <span>{{tab.name}}</span>
          </div>
        </v-tab>
      </v-tabs>
    </div>
    <div class="tw-pt-4 tw-px-4 md:tw-px-0">
      <keep-alive>
        <component v-bind:is="returnMainContent()"></component>
      </keep-alive>
    </div>
  </div>
</section>
</template>

<script>

import Description from "@/components/dashboard/components/companyDetails/Description";
import Media from "@/components/dashboard/components/companyDetails/Media";
import Awards from "@/components/dashboard/components/companyDetails/Awards";
import FAQs from "@/components/dashboard/components/companyDetails/FAQs";
import Reviews from "@/components/dashboard/components/companyDetails/Reviews";
import {mapGetters} from "vuex";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";

export default {
name: "CompanyDetailsOverview",
  components: {Loader, Awards, Media,Reviews, Description, FAQs},
  data(){
  return{
    active_tab: "",
    mode: "",
    tabs: [
      {
        id: 0,
        name: "Description"
      },
      {
        id: 1,
        name: "Media"
      },
      {
        id: 2,
        name: "Reviews"
      },
      {
        id: 3,
        name: "Awards"
      },
      {
        id: 4,
        name: "FAQs"
      },
    ],
  }
  },
  methods:{
      switchTab(name) {
        this.mode = name;
        if(name === 'Description') {
          this.$router.push({params:{tab: "description"}})
        } else if(name === 'Media') {
          this.$router.push({params:{tab: "media"}})
        }else if(name === 'Reviews') {
          this.$router.push({params:{tab: "reviews"}})
        }else if(name === 'Awards') {
          this.$router.push({params:{tab: "awards"}})
        }else if(name === 'FAQs') {
          this.$router.push({params:{tab: "faqs"}})
        } else {
          this.$router.push({params:{tab: "description"}})
        }
      },
      returnMainContent() {
        // let tab = this.mode;
        if (this.$route.params.tab === "description") {
          this.active_tab = 0
          return "Description";
        }
        else if (this.$route.params.tab === "media") {
          this.active_tab = 1
          return "Media";
        }
        else if (this.$route.params.tab === "reviews") {
          this.active_tab = 2
          return "Reviews";
        }
        else if (this.$route.params.tab === "awards") {
          this.active_tab = 3
          return "Awards";
        }
        else if(this.$route.params.tab === "faqs") {
          this.active_tab = 4
          return "FAQs";
        }
        else{
          this.active_tab = 0
          return "Description";
        }
      },
  },
  created() {
    this.$store.dispatch("editCompany/getDescriptionAndMedia", this.$siteName())
  },
  computed:{
...mapGetters('editCompany', ['isLoading'])
  }

}
</script>

<style scoped>
span{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #4F4F4F;
}
.tabbs ::v-deep .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none;
  visibility: hidden;
}
</style>