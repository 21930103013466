<template>
  <Onboardlayout>
    <template #header>
      <Header>
        <template #right-header-component>
          <ProfileDropDown/>
        </template>
      </Header>
    </template>
    <template #main-content>
      <ViewCompanyProfile/>
    </template>
  </Onboardlayout>
</template>

<script>

// import RecruiterDashboardLayout from "@/components/dashboard/layouts/RecruiterDashboardLayout";
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import ViewCompanyProfile from "@/components/dashboard/components/ViewCompanyProfile";
import Onboardlayout from "@/components/onboarding/layouts/Onboardlayout";

export default {
  name: "EditCompanyProfileView",
  components: {Onboardlayout, ViewCompanyProfile, ProfileDropDown, Header}
}
</script>

<style scoped>

</style>