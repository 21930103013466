<template>
  <div class="tw-mt-10 mainDiv">
    <!--  DESCRIPTION VIEW-->
    <div v-if="!edit" >
      <div class="d-flex align-center justify-space-between">
        <h3>About</h3>
        <v-btn @click="switchToEditView" class="text-capitalize transparent-btn">
          <vue-feather type="edit-3" stroke="#008EEF" size="16" class="mr-1"/>
          <span>Edit</span>
        </v-btn>
      </div>
      <div class="tw-mt-3">
        <h5 >{{companyDescriptionAndMedia.company_overview}}</h5>
      </div>
      <div v-if="companyDescriptionAndMedia.company_vision && companyDescriptionAndMedia.company_mission !== ''"
           class="tw-mt-10 tw-flex tw-flex-col lg:tw-flex-row md:tw-justify-between ">
        <div class="md:tw-mr-4">
          <h4 class="tw-mb-4">Vision</h4>
          <h5 class="md:tw-w-300">{{companyDescriptionAndMedia.company_vision}}</h5>
        </div>
        <div class=" tw-mt-8 lg:tw-mt-0 ">
          <h4 class="tw-mb-4">Mission</h4>
          <h5 class="md:tw-w-300">{{companyDescriptionAndMedia.company_mission}}</h5>
        </div>
      </div>
    </div>
    <!-- EDIT DESCRIPTION VIEW-->
    <div v-if="edit">
      <ValidationObserver ref="observer" v-slot="{handleSubmit}">
        <v-form id="about" @submit.prevent="handleSubmit(save)">
          <ValidationProvider
              name="Company Description"
              rules="required|max:250"
              v-slot="{ errors }">
            <div>
              <div class="mb-1">
                <small>Company Description</small>
              </div>
              <v-textarea class="font-weight-regular " :rules="rules" :maxlength="maxlength" placeholder="Brief description of the company" v-model=" companyDescriptionAndMedia.company_overview"  hide-details  solo/>
              <span v-show="errors" class="err">{{ errors[0] }} </span>
            </div>
          </ValidationProvider>
          <ValidationProvider
              name="Company Vision"
              rules="required"
              v-slot="{ errors }">
            <div class="tw-my-6">
              <div class="mb-1">
                <small>Vision</small>
              </div>
              <v-text-field  class="font-weight-regular" :rules="rules" :maxlength="maxlength" placeholder="Company’s Vision" v-model="companyDescriptionAndMedia.company_vision"  hide-details  solo/>
              <span v-show="errors" class="err">{{ errors[0] }} </span>
            </div>
          </ValidationProvider>
          <ValidationProvider
              name="Company Mission"
              rules="required|max:250"
              v-slot="{ errors }">
            <div>
              <div class="mb-1">
                <small>Mission</small>
              </div>
              <v-text-field  class="font-weight-regular" :rules="rules" :maxlength="maxlength" placeholder="Company’s Mission" v-model="companyDescriptionAndMedia.company_mission"  hide-details  solo/>
              <span v-show="errors" class="err">{{ errors[0] }} </span>
            </div>
          </ValidationProvider>

          <div class="d-flex justify-end tw-mt-6">
            <div class="tw-mr-4">
              <BaseButton type="button" @click="cancel" outlined button-text="Cancel"></BaseButton>
            </div>
            <div>
              <BaseButton :loading="loading" type="submit" button-text="Save"></BaseButton>
            </div>

          </div>
        </v-form>
      </ValidationObserver>
    </div>

  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {editCompanyProfile} from "@/services/api/APIService"
import {mapGetters} from "vuex"
export default {
  name: "Description",
  components: {BaseButton},
  data(){
    return{
      edit: false,
      description:"",
      vision: "",
      mission:"",
      loading: false,
      maxlength: 250,
      rules: [v => v.length <= 250 || 'Max 250 characters']
    }
  },
  methods:{
    switchToEditView(){
        this.edit= true
    },
    save(){
      this.loading = true
      let data ={
        company_overview: this.companyDescriptionAndMedia.company_overview,
        company_vision: this.companyDescriptionAndMedia.company_vision,
        company_mission: this.companyDescriptionAndMedia.company_mission
      }
      console.log(this.$siteName(), data)
      editCompanyProfile(data, this.$siteName()).then(
          res =>{console.log(res)
            this.edit= false}
      ).catch(err =>{console.log(err)}
      ).finally( ()=>{this.loading = false})
    },
    cancel(){
      this.companyDescriptionAndMedia.company_overview = this.description
      this.companyDescriptionAndMedia.company_vision = this.vision
      this.companyDescriptionAndMedia.company_mission = this.mission
      this.edit= false

    }
  },
  computed:{
    ...mapGetters('editCompany', ["companyDescriptionAndMedia"])
  },
  mounted() {
    this.description = this.companyDescriptionAndMedia.company_overview
    this.vision = this.companyDescriptionAndMedia.company_vision
    this.mission =this.companyDescriptionAndMedia.company_mission
    // console.log(this.description, this.vision, this.mission)
  }
}
</script>

<style scoped>
.transparent-btn {
  background-color: transparent !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
btnDiv ::v-deep .theme--light.v-btn.v-btn--has-bg{
  background: transparent;
}
small{
  color: #1E323F;
}
span{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}
h3{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #475661;
}
h4{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #9CABB5;
}
h5{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.mainDiv{
  max-width: 630px;
}
.err{
  font-family: "DM Sans",serif;
  color: red;
  font-size: 13px;
}
</style>