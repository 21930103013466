import { render, staticRenderFns } from "./AwardIcon.vue?vue&type=template&id=05235e2b&scoped=true&"
import script from "./AwardIcon.vue?vue&type=script&lang=js&"
export * from "./AwardIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05235e2b",
  null
  
)

export default component.exports