<template>
  <div>
    <view-company-layout>
      <template #dashboard-content-header>
        <div id="background" :style="{background:`url(${getCompanyProfile.cover_image}) no-repeat`, backgroundColor:'#C5E7FF',backgroundSize:'cover'}"
             class="tw-h-32 mt-3 tw-w-full tw-bg-no-repeat">
          <div class="background_cover_image">
            <v-img
                alt="cover_image"
                width="100%"
              :src="getCompanyProfile.cover_image"
              />
          </div>

          <div class="tw-pt-3 tw-hidden md:tw-block tw-pl-4 lg:tw-pl-16">
            <button id="back" @click="back" class="backBtn pa-1 d-flex align-center">
              <vue-feather size="16" type="arrow-left" style="color: #475661"/>
              <h6 class="ml-1">Back</h6></button>
          </div>
          <div class="d-flex justify-end tw-pt-8 tw-pr-4 lg:tw-pr-20 uploadButton">
            <v-card :loading="btnLoading" flat id="upload" class="uploadCoverBtn sm:tw-pb-10 py-2 px-3"
            >
              <input
                  id="assetsFieldHandles"
                  ref="files"
                  accept="image/png, image/gif, image/jpeg,image/jpg"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  name="fields[assetsFieldHandle][]"
                  style="display: none"
                  type="file"
                  @change="uploadTo()"

              />
              <label style="cursor: pointer" for="assetsFieldHandles" class="d-flex align-center">
                <vue-feather type="upload" stroke="#475661" size="16" class="mr-1"/>
                <span class="tw-hidden md:tw-block ">Upload Cover Image</span>
              </label>
            </v-card>
          </div>
        </div>
      </template>
      <template #dashboard-company-image>
        <div class="mar lg:tw-pl-16 tw-px-4 company__logo">
          <div>
            <div v-if="getCompanyProfile.company_logo">
              <img style="background: #ffffff" alt="award-image" class="rounded avatar img" width="60" height="60"
                   :src="getCompanyProfile.company_logo">
            </div>
            <div v-else>
              <v-avatar class="avatar tile rounded" style="border-radius: 3px" size="60"><h3>
                {{ initials(getCompanyProfile.company_name) }}</h3></v-avatar>
            </div>
          </div>
        </div>
      </template>

      <template #dashboard-sideNav>
        <ViewCompanySideContent :get-company-profile="getCompanyProfile"/>

      </template>

      <template #dashboard-content>
        <CompanyDetailsOverview/>
      </template>

    </view-company-layout>

  </div>
</template>

<script>

import getSiteName from "@/services/utils";
import {mapGetters, mapMutations} from "vuex";
// import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import CompanyDetailsOverview from "@/components/dashboard/components/companyDetails/CompanyDetailsOverview";
import ViewCompanyLayout from "@/components/dashboard/components/companyDetails/ViewCompanyLayout";
import ViewCompanySideContent from "@/components/dashboard/components/companyDetails/ViewCompanySideContent";
import {uploadCompanyCoverImage} from "@/services/api/APIService";
import {uploadMediaFile} from "@/services/utils/fileUploader";

export default {
  name: "ViewCompanyProfile",
  components: {
    ViewCompanySideContent,
    ViewCompanyLayout,
    CompanyDetailsOverview
  },
  data() {
    return {
      loading: false,
      companyInfo: {},
      file: '',
      imageInfo: '',
      image_url: '',
      btnLoading: false,
    }
  },

  computed: {
    ...mapGetters('editCompany', ['getCompanyProfile', 'getCompanyAdmin', 'companyDescriptionAndMedia'])
  },

  async created() {
    this.loading = true
    await this.$store.dispatch('editCompany/getCompanyProfile', getSiteName() || this.$route.query.site_name)
    await this.$store.dispatch('editCompany/getCompanyAdmins', getSiteName() || this.$route.query.site_name)
    console.log(this.companyDescriptionAndMedia)
  },

  methods: {
    ...mapMutations('editCompany', ['setCoverImage']),
    async handleCoverImage(file) {
      this.btnLoading = true
      const { key: url, name, size } = await uploadMediaFile(file, 'company/cover_image')
      this.imageInfo = {url: url, name: name, size: size};
      let site_name = (sessionStorage.getItem('site_name'))
      let data = {}
      data.cover_image = this.imageInfo.url
      await uploadCompanyCoverImage(site_name, data).then(res => {
            console.log(res)
            this.$displaySnackbar({message: 'Cover image uploaded', success: true})
           this.$store.dispatch('editCompany/getCompanyProfile', getSiteName() || this.$route.query.site_name)
        this.setCoverImage(this.imageInfo.url)
        this.btnLoading = false

          }
      ).catch(e => {
            console.log(e)
            this.btnLoading = false
            this.$displaySnackbar({message: 'Cover image failed to Upload', success: false})
          }
      )
    },
    async uploadTo() {
      if (!this.getCompanyProfile.verified) {
        this.$displaySnackbar({message: 'Your organisation has not been verified', success: false})
        return
      }
      this.file = this.$refs.files.files[0];
      const exts = ['jpeg', 'png', 'gif', 'jpg']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension.toLowerCase())) {
        console.log('d')
        this.$displaySnackbar({message: 'Only accepts Image files', success: false})
        this.file = "";
        return
      }
      try {
        await this.handleCoverImage(this.file);
      } catch (e) {
        return;
      }
    },
    initials(name) {
      if (name) {
        let firstName = name.split(" ");
        const initial = firstName.shift().charAt(0);
        return initial.toUpperCase();
      }
    },

    backTo() {
      this.$router.push({name: 'RecruiterDashboard'})
    },

    back() {
      this.$router.push({name: 'RecruiterDashboard'})
    },

    editSocialAcc() {
      this.$router.push({name: 'EditCompanySocialView'})
    },

    allAdmin() {
      this.$router.push({name: 'EditCompanyAdminView'})
    }
  }
}
</script>

<style scoped lang="scss">
.uploadButton {
  @media (max-width: 760px) {
    position: absolute;
    top: 56px;
    right: 5px;
  }
}

.img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.mar {
  margin-top: -30px;
}

h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;
}

.uploadCoverBtn {
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  transition: all 1s ease !important;
}

.backBtn {
  background: rgba(251, 251, 251, 0.6);
  border-radius: 6px;
}

.avatar {
  border: 2.14286px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4.28571px;
}

.avatar h3{
  font-style: normal;
  font-weight: bold;
  font-size: 25.7143px;
  line-height: 26px;
  color: #FFFFFF;
}
.tile {
  background: #8F47F4;
}

#background {
  position: relative;
  overflow-y: hidden;

  & .background_cover_image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    opacity: 0.8;
  }
}

.backBtn {
  outline: none;
  position: absolute;
  z-index: 2;
}

.company__logo {
  position: absolute;
  z-index: 2;
  top: 204px;
}
</style>
