<template>
  <section>
    <div class="tw-mt-6 pa-6 ">

      <div>
        <h2 id="compname" class="compName text-capitalize">{{ getCompanyProfile.company_name || '- - -' }}</h2>
        <p id="type" class="industry tw-mr-1">{{ getCompanyProfile.industry || '- -' }}</p>
      </div>

      <div class="tw-flex tw-my-5">
        <div class="tw-mr-5">
          <h4 class="font-weight-bold ">{{ getCompanyProfile.followers }} </h4>
          <span id="followers" class="industry">Followers</span>
        </div>
        <div class="tw-mr-5">
          <h4 class="font-weight-bold">{{ getCompanyProfile.reviews }} </h4>
          <span id="reviews" class="industry">Reviews</span>
        </div>
        <div>
          <h4 class="font-weight-bold">{{ getCompanyProfile.jobs }} </h4>
          <span id="jobs" class="industry">Jobs</span>
        </div>
      </div>

      <div class="tw-pb-10">
        <BaseButton id="edit" class="" @click="editSocialAcc" width="190px" height="40px" outlined
                    button-text="Edit"/>
      </div>

      <div class="lg:tw-block">
        <div class="tw-flex">
          <vue-feather size="19" type="map-pin" class="mr-2"/>
          <p id="location" class="numOfStaff">{{ getCompanyProfile.location || '- -' }}</p>
        </div>
        <div class="tw-flex py-1">
          <vue-feather size="19" type="users" class="mr-2"/>
          <p id="employee" class="numOfStaff">{{ getCompanyProfile.company_size || 'No' }} Employees</p>
        </div>
        <div class="tw-pb-5">
          <div
              v-if="!getCompanyProfile.facebook && !getCompanyProfile.instagram && !getCompanyProfile.linkedin && !getCompanyProfile.twitter">
            <p id="social" class="numOfStaff tw-mr-10 lg:tw-mr-0">No Social Links</p>
          </div>
          <div v-else class="tw-flex">
            <a target="_blank" :href="getCompanyProfile.facebook"><FacebookIcon class="tw-ml-2 tw-mt-1" v-if="getCompanyProfile.facebook"/></a>
            <a target="_blank" :href="getCompanyProfile.instagram"><InstagramIcon class="tw-ml-2 tw-mt-1" v-if="getCompanyProfile.instagram"/></a>
            <a target="_blank" :href="getCompanyProfile.twitter"><TwitterIcon class="tw-ml-2 tw-mt-1" v-if="getCompanyProfile.twitter"/></a>
            <a target="_blank" :href="getCompanyProfile.linkedin"><LinkedInIcon class="tw-ml-2 tw-mt-1" v-if="getCompanyProfile.linkedin"/></a>
          </div>
        </div>
      </div>
      <a
          target="_blank"
          :href="getCompanyProfile.website"
          id="website"
          v-if="getCompanyProfile.website"
      >
        <div class="tw-flex" v-show="getCompanyProfile.website">
          <p id="http" class="numOfStaff tw-mr-1" style="color: #008EEF">
            Visit website
          </p>
          <ExternalLinkIcon style="color: #008EEF; height: 15px; width: 15px; margin-top: 3px"/>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import FacebookIcon from "@/components/UIComponents/reusablesIcon/FacebookIcon";
import InstagramIcon from "@/components/UIComponents/reusablesIcon/InstagramIcon";
import TwitterIcon from "@/components/UIComponents/reusablesIcon/TwitterIcon";
import LinkedInIcon from "@/components/UIComponents/reusablesIcon/LinkedInIcon";
import BaseButton from "@/components/UIComponents/BaseButton";
import {ExternalLinkIcon} from "vue-feather-icons"
export default {
  name: "ViewCompanySideContent",
  components: {BaseButton,
    FacebookIcon, InstagramIcon, TwitterIcon, LinkedInIcon, ExternalLinkIcon},
  props:{
    getCompanyProfile: Object
  },
  methods: {
    editSocialAcc() {
      this.$router.push({name: 'EditCompanySocialView'})
    },
  }
}
</script>

<style scoped>

.compName {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #1E323F;
  margin-bottom: 0;
}

.industry {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.compSize {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #9CABB5;
  margin-top: 30px;
  margin-bottom: 6px;
}

.numOfStaff {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.links {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #828282;
  margin-top: 30px;
  margin-bottom: 8px;
}

.initials {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #008EEF;
}

</style>
