<template>
  <v-dialog v-model="showDialog" persistent max-width="420px">
    <template v-slot:activator="{ on, attrs }">
      <div
          v-bind="attrs"
          id="delete-faq"
          v-on="on"
      >
        <slot></slot>
      </div>
    </template>
    <v-card class="tw-p-4">
      <div class="d-flex align-center justify-space-between ">
        <h3 class="text-capitalize">Delete {{ name }}</h3>
        <v-btn icon dark style="color: black" @click="showDialog=false" id="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
     <div class="tw-py-4">
       <h5>Are you sure you want to delete this {{ name }}? </h5>
     </div>

      <div class="tw-flex actions tw-justify-start sm:tw-justify-end mt-6">
        <base-button @click="showDialog=false" depressed id="Cancel" class="mr-4" outlined  button-text="Cancel"/>
        <base-button @click="$emit('delete',  showDialog = loading )" type="submit" :loading="loading" depressed id="delete" button-text="Yes"/>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
export default {
name: "DeleteModal",
  components: {BaseButton},
  data(){
  return{
    showDialog: false
  }
  },
  props:{
    loading: Boolean,
    // showDialog: Boolean,
    name: String
  }
}
</script>

<style scoped>
h3{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #2B1C1C;
}
h5{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
</style>