<template>
<div class="mainDiv tw-mb-4">
  <div class="d-flex justify-end tw-my-4">
    <CreateAwardModal modal-title="Award Details">
    <v-btn class="text-capitalize transparent-btn">
      <vue-feather type="plus" stroke="#008EEF" size="16" class="mr-1"/>
      <span>Add Award</span>
    </v-btn>
    </CreateAwardModal>
  </div>
  <div v-if="companyAwards.length === 0">
    <CompanyProfileEmptyState>
<!--      <img alt="No Awards" src="https://res.cloudinary.com/semicolon-dev/image/upload/v1635261336/semicolonAfrica/Frame_48096393_r0tby3.png"/>-->
      <AwardIcon/>
      <h2 class="tw-pt-8 no-reviews-text">No Awards</h2>
    </CompanyProfileEmptyState>
  </div>
  <div v-else>
    <div  v-for="(item, index) in companyAwards" :key="index">
    <v-card flat class="d-flex card tw-py-4 justify-space-between">
      <div class="d-flex tw-items-start ">
        <div  v-if="item.image">
          <img alt="award-image" class="rounded" :src="item.image">
        </div>
        <div v-if="!item.image">
          <v-avatar color="#8F47F4" class="avatar tile rounded" style="border-radius: 3px" size="60">
          <h3>{{initials(item.title)}}</h3></v-avatar>
        </div>
        <div class="tw-ml-3">
          <h4 class="text-capitalize">{{ item.title }}</h4>
          <h5 class="tw-pt-1.5 text-capitalize">{{ item.organiser }} - {{item.year_awarded}}</h5>
        </div>
      </div>

      <v-menu v-model="item.openMenu" bottom offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <vue-feather type="more-vertical"/>
          </v-btn>
        </template>
        <v-list style="width: 192px">
          <v-list-item class="mx-1 mt-1">
              <v-list-item-title id="edit">
                <CreateAwardModal :item="item" modal-title="Edit Award Details">
                    <h6>Edit</h6>
                </CreateAwardModal>
              </v-list-item-title>
          </v-list-item>
          <div  class="menu">
            <v-divider></v-divider>
          </div>
          <v-list-item class="mx-1 mt-1">
            <v-list-item-title id="delete">
              <DeleteModal :name="'award'"  :loading="loading" @delete="deleteItem(item)">
                <h6>Delete</h6>
              </DeleteModal>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-card>
    </div>
  </div>
</div>
</template>

<script>
import AwardIcon from "@/components/dashboard/reuseables/AwardIcon";
import CreateAwardModal from "@/components/dashboard/components/companyDetails/modals/CreateAwardModal";
import CompanyProfileEmptyState from "@/components/dashboard/components/companyDetails/CompanyProfileEmptyState";
import {mapGetters} from "vuex";
import DeleteModal from "@/components/dashboard/components/companyDetails/modals/DeleteModal";
export default {
name: "Awards",
  components: {AwardIcon,DeleteModal, CompanyProfileEmptyState, CreateAwardModal},
  data(){
    return{
      loading:false,
      dialog:false,
      awards:[],

    }
  },
  computed:{
  ...mapGetters('editCompany', ["companyAwards"])
  },
  methods:{
    initials(name) {
      if(name) {
        let firstName = name.split(" ");
        const initial = firstName.shift().charAt(0);
        return initial.toUpperCase();
      }
    },
    deleteItem(item){
      this.loading = true
      this.$store.dispatch("deleteCompanyAward", item.id).then(
          res =>{console.log(res)
            this.dialog = false}
      ).catch(err =>{console.log(err)}).finally(()=>{this.loading = false})
    },

  },
  created() {
  if(!this.companyAwards.length){
    this.$store.dispatch("editCompany/getCompanyAwards", this.$siteName())

  }
  }
}
</script>

<style scoped>
.transparent-btn {
  background-color: transparent !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
img{
  height: 60px;
  width: 60px;
}
span{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}
.mainDiv{
  max-width: 630px;
}
.avatar{
  /*background: #8F47F4;*/
  border: 2.14286px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4.28571px;
}
.card{
  border-bottom: 1px solid rgba(208, 220, 228, .3);
}
h4{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}
h5{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
h3{
  font-style: normal;
  font-weight: bold;
  font-size: 25.7143px;
  line-height: 26px;
  color: #FFFFFF;
}
.menu >>> .theme--light.v-divider{
  border-color: rgba(251, 251, 251, 1);
}
</style>